<template>
  <div>
    <b-container class="white-card">
      <b-row><b-col><b-img fluid src="@/assets/lacken.jpg" style="margin-bottom:50px" alt="Telge Lackering"></b-img></b-col></b-row>
      <b-row>
        <b-col lg>
          <h1>Kontakta oss</h1>
          <p id="contact">
            <strong>Telge Lackering AB</strong><br/>
            Gröndalsvägen 2b<br/>
            151 65 Södertälje<br/>
            <br/>
            <a href="mailto:order@telgelack.se">Order@telgelack.se</a>
            <br/>
            <a href="tel:0855440655">08-554-406-55</a>
            <br/>
            <br/>
            <a href="mailto:Lars@telgelack.se">Lars@telgelack.se</a>
            <br/>
            <a href="tel:0706309500">070-630-9500</a>
            <br/>
            <br/>
            <a href="mailto:Joonas@telgelack.se">Joonas@telgelack.se</a>
            <br/>
            <a href="tel:0725007100">072-500-7100</a>
          </p>
        </b-col>
        <b-col lg>
          <iframe src="https://www.google.com/maps/embed/v1/place?q=59.178501,+17.600819&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameborder="0" style="border:0;width:100%;height:450px;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
export default {
  name: 'ContactView',
  components: {
    BImg
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contact {
  text-align: left;
  margin: auto;
  display: table;
}
</style>
